.footer {
  width: 60%;
  color: #666;
  margin: 0 auto;
  z-index: 666;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  &-top {
    width: 100%;
    ul {
      li {
        list-style: none;
      }
      &::after {
        content: "";
        width: 100%;
        height: 1px;
        background: #666;
        position: absolute;
        left: 50%;
        margin-top: 10px;
        transform: translate(-50%);
      }
    }
  }
  &-content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    ul {
      display: flex;
      justify-content: center;
      li {
        list-style: none;
        margin: 0 5px;
        cursor: pointer;
        &:hover{
          color: #000;
        }
      }
    }
  }
}
