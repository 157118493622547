.cartoon-hot{
    flex: 2.5;
    text-align: start;
    border-right: 1px solid #ccc;
    p {
        flex: 2;
        font-size: 20px;
        padding-bottom: 10px;
        margin: 10px 30px 0 30px;
        border-bottom: 1px dotted #ccc;
      }
      &-info{
        display: flex;
        flex-wrap: wrap;
        position: relative;
        &-list{
          width: 40%;
          margin: 20px 0 0 50px;
          padding-bottom: 10px;
          border-bottom: 1px solid #ccc;
          span {
            font-size: 15px;
            color: #000;
            margin-left: 20px;
          }
          a {
            color: #333;
            cursor: pointer;
            font-size: 12px;
            line-height: 20px;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
          }
      }
    }

.cartoon-about{
    flex:1;
    text-align: start;
    min-height: 295px;
    p {
        font-size: 20px;
        padding-bottom: 10px;
        margin: 10px 30px 0 30px;
        border-bottom: 1px dotted #ccc;
      }
}
