.left-side {
  width: 125px;
  top: 50%;
  position: fixed;
  background: #fff;
  border: 1px solid #ccc;
  transition: transform 1s;
  transform: translate(-127px, -50%);
  ul {
    width: 100px;
    padding: 0;
    margin: 10px auto;
    li {
      font-size: 15px;
      padding: 2px;
      margin: 5px 0;
      list-style: none;
      a {
        text-decoration: none;
        color: #333;
      }
      :hover {
        color: #dd6f00;
      }
    }
  }
  p {
    color: #fff;
    padding: 5px;
    font-size: 15px;
    transition: font-size 0.2s;
    background: #004080;
    white-space: nowrap;
    top: -10px;
    left: 130px;
    position: absolute;
    border: 1px solid #ccc;
    &:hover {
      font-size: 20px;
    }
  }
}

.right-side {
  position: fixed;
  z-index: 666;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 1s;
  .imgbox {
    overflow: hidden;
    position: relative;
    width: 300px;
    &::after {
      content: "";
      display: block;
      margin-top: 100%;
    }
    img {
      max-width: 300px;
      max-height: 200px;
      left: 0;
      bottom: 0;
      position: absolute;
    }
  }
  a {
    color: #333;
    text-decoration: none;
    &:hover {
      color: #004080;
    }
  }
}
