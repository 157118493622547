body {
  margin: 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #eee;
  font-family: "微软雅黑", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  * a {
    text-decoration: none;
  }
}

@media screen and (max-width: 1280px) {
  .header,
  .content,
  .footer {
    width: 80%;
  }
}
@media screen and (max-width: 800px) {
  .header,
  .content,
  .footer {
    width: 90%;
  }
  .right-side {
    display: none;
  }
}
