.slider-img {
  width: 100%;
  display: block;
  position: relative;
  padding-bottom: 1px;
  .imgbox {
    overflow: hidden;
    position: relative;
    width: 100%;
    &::after {
      content: "";
      display: block;
      margin-top: 25%;
    }
    img {
      border-radius: 8px 8px 0 0;
      width: 100%;
      height: 100%;
      animation: show 0.8s;
      position: absolute;
      @keyframes show {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
  }

  .log-reg {
    width: 100px;
    height: 40px;
    position: absolute;
    top: 5%;
    right: 3%;
    opacity: 0.6;
    cursor: pointer;
    border-radius: 2px;
    border: 1px solid #666;
    background: #fff;

    a {
      font-size: 20px;
      line-height: 40px;
    }
    &:hover {
      opacity: 1;
    }
  }
}
.slider-img ~ div {
  height: 66px;
  width: 15%;
  background-color: #fff;
  border-right: 1px solid #ccc;
  border-radius: 0 0 0 8px;
  p {
    margin: 0;
    padding-top: 15px;
    font-size: 15px;
    cursor: pointer;
    a:last-child {
      display: inline-block;
      margin-top: 3px;
    }
  }
}
.active-border {
  border-top: 9px solid #ccc;
  margin-bottom: 0 !important;
}
aside {
  flex: 2;
  margin-left: 2%;
  text-align: left;
  letter-spacing: 1px;
  p {
    margin-bottom: 0;
    border-bottom: 1px dotted #ccc;
  }
}
