.news-left {
  width: 40%;
  min-height: 150px;
  border-right: 1px solid #ccc;
  div {
    width: 50%;
    margin: -10px 0 0 -20px;
  }
}

.news-right {
  flex: 2;
  text-align: start;
  position: relative;
  p {
    font-size: 20px;
    margin: 10px 0 0 30px;
  }
  .news-slider {
    position: absolute;
    width: 70%;
    margin-left: 20px;
    display: flex;
    justify-content: flex-start;
    .slidebox {
      overflow: hidden;
      .imgbox {
        position: relative;
        display: flex;
        justify-content: flex-start;
        margin-top: 5px;
        transition: left 1s;
        div {
          margin: 20px 30px 0 0;
          text-align: center;
          img {
            width: 50px;
            height: 50px;
          }
          p {
            margin: 0;
            font-size: 12px;
            color: #333;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
