.show-content_one {
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
  padding: 0;
  img {
    width: 150px;
    height: 100px;
    animation: show 1s;
    margin: 0 20px;
  }
}
.show-content {
  display: flex;
  display: none;
  text-align: start;
  position: relative;
  animation: show 0.8s;
  p {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
  }
  a {
    color: #333;
    cursor: pointer;
    font-size: 12px;
    line-height: 20px;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
